<template>
  <div class="items-center">
    <!-- status display -->
    <h3
      class="flex flex-1 items-center text-base px-3 py-2.5 rounded-md"
      :class="{
        'bg-yellow-100': pendingCancel,
        'bg-green-100 border border-green-300':
          status === 'Active' || (!pendingCancel && !cancelled && !policyData.isExpired),
        'bg-gray-100': policyData.isExpired || cancelled || (isNonRenewal && isEarthquake)
      }"
    >
      <!-- x-circle -->
      <svg
        v-if="policyData.isExpired || cancelled || (isEarthquake && isNonRenewal)"
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 text-gray-400 mr-1 mt-[1px]"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>

      <svg
        v-else-if="pendingCancel"
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 text-yellow-700 mr-1 mt-[1px]"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>

      <!-- checkmark -->
      <svg
        v-else
        data-test="policyStatusIcon"
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 text-green-600 mr-1 mt-[1px]"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
          clip-rule="evenodd"
        />
      </svg>

      <div v-if="isEarthquake && isNonRenewal" class="text-gray-500 !text-lg font-bold">
        Non-renew
      </div>
      <div
        v-else
        class="flex-1 status !text-lg font-bold"
        :class="{
          'text-yellow-900': pendingCancel,
          'text-gray-500': cancelled || policyData.isExpired
        }"
        data-test="policyStatus"
      >
        {{ status }}
      </div>
    </h3>

    <!-- Buttons -->
    <div v-if="!$readonly" class="flex justify-end mt-2">
      <div v-if="canCancel && !isEarthquake">
        <button
          class="!text-sm text-red-600 px-2 py-1 rounded font-bold hover:bg-red-100 transition duration-200 ease-in-out !m-0 border border-gray-300 hover:border-red-300"
          :disabled="!policyData.canCancelPolicy || $readonly"
          data-test="cancelPolicyButton"
          @click="initCancel"
        >
          Cancel Policy
        </button>
      </div>
      <div v-if="isEarthquake && !isNonRenewal && $internal">
        <button
          class="!text-sm border border-gray-300 text-red-600 px-2 py-1.5 rounded font-bold hover:bg-red-700 hover:border-red-700 hover:text-white transition duration-200 ease-in-out !m-0"
          data-test="cancelPolicyButton"
          @click="initNonRenew"
        >
          Non-Renew
        </button>
      </div>

      <div v-if="(cancelled || pendingCancel) && internal">
        <button
          class="!text-sm px-3 py-1.5 rounded font-bold transition duration-200 ease-in-out !my-0 !mr-0 !ml-1 text-white"
          :class="{
            'bg-deepsea-full hover:bg-deepsea-full/75': !$isEarthquake,
            'bg-brand-orange hover:bg-brand-orange/75': $isEarthquake
          }"
          data-test="reinstatePolicyButton"
          @click="$root.$emit('openReinstatementModal', { policyData })"
        >
          Reinstate Policy
        </button>
      </div>
    </div>

    <!-- Cancel Policy Dialog -->
    <cancel-wizard-dialog
      :visible.sync="showCancel"
      :top="null"
      :auto-cancel="!!autoCancelReason"
      class="centered-dialog__wrapper"
      custom-class="centered-dialog"
      append-to-body
      @cancel="cancel()"
      @fetchPendingPremium="fetchPendingPremiumMethod()"
    />

    <el-dialog
      title="Finalize Cancellation"
      custom-class="dialog-class"
      :visible.sync="showCancelModal"
      data-test="cancelFinalization"
      append-to-body
    >
      Are you sure you wish to cancel?
      <span slot="footer" class="dialog-footer">
        <div v-if="$internal" class="flex switch-spec">
          <el-switch v-model="suppressValue" class="mr-5 mt-1" active-color="#004C9D" />

          <p v-if="!suppressValue" class="margin-nudge" data-test="cancelNotifsSuppressed">
            Notifications suppressed
          </p>
          <p v-else class="margin-nudge" data-test="cancelNotifsActive">Notifications active</p>
        </div>
        <button
          data-test="cancelCloseModal"
          class="inline-flex items-center mr-2 px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-deepsea-medium"
          @click="showCancelModal = false"
        >
          Cancel
        </button>
        <el-button
          v-loading="loading"
          data-test="cancelConfirmButton"
          class="!bg-deepsea-full !text-white hover:!bg-deepsea-full/75"
          @click="autoCancelFromModal"
          >Confirm</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="Confirm Non-Renewal"
      custom-class="dialog-class"
      :visible.sync="showNonRenewModal"
      append-to-body
    >
      You are about to non-renew this policy. Please add a note below.

      <el-input v-model="nonRenewModalNote" class="mt-3" />
      <span slot="footer" class="dialog-footer">
        <button
          class="inline-flex items-center mr-2 px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-deepsea-medium"
          @click="showNonRenewModal = false"
        >
          Cancel
        </button>
        <button
          v-loading="loading"
          :disabled="!nonRenewModalNote"
          class="inline-flex items-center mr-2 px-4 py-2 border shadow-sm rounded-md text-sm font-semibold text-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          :class="{
            'border-red-400 bg-red-400 cursor-not-allowed': !nonRenewModalNote,
            'border-red-700 bg-red-700 hover:!bg-red-700/75': !!nonRenewModalNote
          }"
          @click="nonRenewPolicy"
        >
          Confirm
        </button>
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="showFinalize"
      append-to-body
      :top="null"
      class="centered-dialog__wrapper"
      custom-class="centered-dialog"
      data-test="cancelDialog"
      @opened="focusNotes"
    >
      <template #title>
        <div class="flex items-center px-2">
          <span class="el-icon-document-checked" /> Finalize Cancellation
        </div>
      </template>

      <template #default>
        <div class="flex flex-col content-wrap">
          <label class="text-center mb-5">Include optional notes for additonal info</label>
          <el-input
            ref="notes"
            v-model="finalNotes"
            :rows="5"
            type="textarea"
            data-test="cancelOptionalNotes"
          />
        </div>
      </template>

      <template #footer>
        <div class="buttons flex justify-end">
          <el-button
            size="small"
            type="danger"
            data-test="cancellationFinalButton"
            @click="finalizeCancel"
          >
            Finalize Cancellation
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import CancelWizardDialog from "./CancelWizardDialog.vue";

import { asyncTimeout } from "../../../store/modules/payment/actions";
import { authAxios } from "@/store/modules/auth";
import { Constants } from "@/config/index";

export default {
  components: {
    CancelWizardDialog
  },
  props: {
    datecheck: {
      type: String
    }
  },
  data() {
    return {
      showCancel: false,
      showFinalize: false,
      finalNotes: null,
      autoCancelReason: null,
      suppressValue: true,
      showCancelModal: false,
      loading: false,
      showReinstateModal: false,
      showNonRenewModal: false,
      nonRenewModalNote: "",
      chatModal: {
        open: false
      }
    };
  },
  computed: {
    isEarthquake() {
      return (
        this.$store.getters["policy/productNo"] === 4 ||
        this.$store.getters["policy/productNo"] === 5
      );
    },
    /**
     * @type {() => neptune.payloads.IGetConfirmation}
     */
    policyData() {
      /** @type {neptune.IRootState} */
      const {
        policy: { confirmationData }
      } = this.$store.state;

      return confirmationData || {};
    },
    isNonRenewal() {
      return this.$store?.state?.policy?.confirmationData?.isNonRenewal;
    },
    agent() {
      return this.$route.meta?.userType !== 2;
    },
    canCancel() {
      return this.policyData.canCancelPolicy;
    },
    cancelled() {
      return this.$store.getters["policy/allData"].isPolicyCancelled;
    },
    autoCancel() {
      return this.policyData.cancelReasons?.length === 1;
    },
    autoCancelPayload() {
      return {
        PolicyNumber: this.$store.getters["policy/allData"].policyNo,
        ReasonCode: this.policyData.cancelReasons[0],
        NoteInfo: {
          Note: "",
          Private: false
        },
        EffectiveDate: new Date().toISOString(),
        SuppressNotification: !this.suppressValue
      };
    },
    pendingCancel() {
      return this.policyData.isPendingCancel;
    },
    internal() {
      return this.$store.getters["auth/internal"];
    },
    canFinishCancel() {
      return this.pendingCancel && this.internal;
    },
    active() {
      return this.policyData.canCancelPolicy === true || this.policyData.grossPremium !== 0;
    },
    status() {
      if (this.policyData.isPolicyCancelled) return "Cancelled";
      if (this.policyData.isExpired) return "Expired";
      if (this.pendingCancel) return "Pending Cancel";
      if (this.datecheck > new Date().toISOString() && this.active) return "Bound";
      if (this.active) return "Active";

      return "Inactive";
    },
    statusIconClasses() {
      let icon;
      let color = "text-green-700";
      switch (this.status) {
        case "Pending Cancel":
          icon = "el-icon-loading";
          color = "text-orange-700";
          break;
        case "Inactive":
          icon = "el-icon-circle-close";
          color = "text-red-700";
          break;
        case "Bound":
          icon = "el-icon-check";
          color = "text-green-700";
          break;
        default:
          icon = "el-icon-check";
          color = "text-green-700";
          break;
      }

      return [icon, color];
    },
    finalizePayload() {
      return {
        PolicyNumber: this.policyData.policyNo,
        NoteInfo: {
          Note: this.finalNotes,
          Private: false
        }
      };
    }
  },
  watch: {
    autoCancel: {
      handler(val, oldVal) {
        if (val && !oldVal) {
          this.autoCancelReason = this.policyData.cancelReasons[0];
        }
      },
      immediate: true
    }
  },

  methods: {
    initNonRenew() {
      this.showNonRenewModal = true;
    },
    async nonRenewPolicy() {
      try {
        this.loading = true;
        const body = {
          PolicyNo: this.policyData.policyNo,
          Note: this.nonRenewModalNote,
          reasonCode: "99"
        };
        const res = await authAxios.post(Constants.NON_RENEW_POLICIES, body);
        this.$notify({
          title: "Success",
          message: "Policy has been successfully non-renewed!",
          type: "success"
        });
        this.$emit("refreshOnNonRenew");
        return res;
      } catch (error) {
        this.$notify.error({
          title: "Error",
          message: "There was an error non-renewing the policy."
        });
        throw new Error(error);
      } finally {
        this.showNonRenewModal = false;
        this.loading = false;
      }
    },

    async cancel() {
      this.showCancel = false;

      await asyncTimeout(1000);

      /** @type {neptune.IPolicyData} */
      const { email } = this.$store.getters["policy/allData"];
      const isFlatCancel =
        this.$store.getters["policy/allData"].reasonCode === "13" ||
        this.$store.getters["policy/allData"].reasonCode === "05" ||
        this.$store.getters["policy/allData"].reasonCode === "06" ||
        this.$store.getters["policy/allData"].reasonCode === "21" ||
        this.$store.getters["policy/allData"].reasonCode === "23";
      if (isFlatCancel) {
        this.$msgbox({
          title: "Finalizing Cancellation",
          type: "success",
          message: "Your cancellation will be complete in a few minutes after an automatic review."
        });
      } else {
        this.$msgbox({
          title: "Cancellation is Pending",
          type: "success",
          message: `An email has been sent to ${email} for the insured to sign the cancellation request.`
        });
      }
    },
    async initCancel() {
      if (new Date().toISOString() >= this.policyData.inceptionDate && !this.$internal) {
        this.$root.$emit("toggleFinalizeCancellationModal");
        return;
      }

      if (this.autoCancel) this.showCancelModal = true;
      else this.showCancel = true;
    },
    async autoCancelFromModal() {
      try {
        this.loading = true;
        this.$emit("setSubmitting");
        await this.postCancelData();
        this.$emit("fetchPendingPremium");
        this.showCancel = true;
      } catch (error) {
        this.$emit("setUnsubmitting");
        this.$msgbox({
          title: "Error",
          type: "error",
          message: error
        });
      } finally {
        this.loading = false;
        this.$emit("setUnsubmitting");
      }
    },
    async postCancelData() {
      debugger;
      await this.$store.dispatch("policy/cancelPolicy", {
        ...this.autoCancelPayload,
        EffectiveDate: new Date().toISOString()
      });
      this.showCancelModal = false;
      return true;
    },

    focusNotes() {
      this.$refs.notes.focus();
    },

    async signOut() {
      try {
        await this.$msgbox({
          title: "Please Confirm",
          type: "warning",
          message: "Are you sure you want to sign out?",
          showCancelButton: true,
          cancelButtonText: "No",
          confirmButtonText: "Yes",
          lockScroll: false
        });

        delete localStorage.vuex;

        location.reload();
      } catch (error) {}
    },

    async finalizeCancel() {
      await this.$store.dispatch("policy/finalizeCancellation", this.finalizePayload);

      this.showFinalize = false;
    },
    fetchPendingPremiumMethod() {
      this.$emit("fetchPendingPremium");
    }
  }
};
</script>

<style lang="scss" scoped>
.flex-1 .status {
  font-size: 20px;
}

.content-wrap {
  min-width: 500px;
}
::v-deep .dialog-class {
  width: 100%;
  margin: 0 !important;

  @media screen and (min-width: 768px) {
    width: 50%;
    max-width: 480px;
    margin: auto !important;
  }
}

.margin-nudge {
  margin-top: 1px;
}

.disabledClass {
  color: #ff614c !important;
  border: 1px solid #ff614c !important;
}
.switch-spec {
  padding-top: 10px;
  @media screen and (max-width: 1550px) {
    display: flex;
    justify-content: center;
    padding-top: 0px;
    margin-bottom: 10px;
  }
}
</style>
