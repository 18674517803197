import { isAfter } from "date-fns";

export function isSignedByParty({ party, confirmation }) {
  const signatureStatus = confirmation.isSigned.find((s) => s.party === party);
  const { requiresSignatures } = signatureStatus;
  return signatureStatus.isSigned || !requiresSignatures;
}

export function isPaymentRequired({ confirmation }) {
  return confirmation.currentlyDue > 0;
}

export function isBillInitiallyMortgagee(context) {
  const [lastestTransaction] = context.transactions.sort((a, b) => {
    return isAfter(new Date(a.postDate), new Date(b.postDate)) ? -1 : 1;
  });

  if (lastestTransaction.productId !== 1) {
    return false;
  }

  const billOn =
    lastestTransaction.transactionType === "RN"
      ? lastestTransaction.billOnRenewal
      : lastestTransaction.billInitially;

  return billOn === "Mortgage lienholder";
}

export function isFlood(context) {
  if (context.confirmation.productId === 4 || context.confirmation.productId === 5) return false;
  return true;
}

export function isEarthquakeMonthly(context) {
  return (
    (context.confirmation.productId === 4 || context.confirmation.productId === 5) &&
    context.confirmation.paymentInterval.toLowerCase() === "monthly"
  );
}

export function isFeeAcknowledged(context) {
  return Boolean(context.ackFee);
}

export function isEndorsement(context) {
  return context.confirmation?.lastTransactionType === "EB";
}

export function isPolicyholder(context) {
  return context.accountType === "policyholder";
}

export function hasAddressData(context) {
  return (
    !!context.addressData?.polygon ||
    !!context.addressData?.geocode?.lat ||
    !!context.addressData?.geocode?.lng
  );
}
