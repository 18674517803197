import { computed, inject, onBeforeUnmount, onMounted, provide, ref } from "vue";
import { interpret } from "xstate";
import { PolicyWizardMachine } from "./machine";

import axios from "axios";

export const policyWizardKey = Symbol("neptune-policy-wizard");

export function providePolicyWizard({
  agentNo,
  authToken,
  policyNo,
  party,
  isEarthquake,
  accountType,
  addressData,
  onTransition,
  optInAutoPayRenewal
}) {
  let axiosInterceptorId;
  const policyWizardMachine = ref();
  const policyWizardService = ref();
  const state = ref();
  const context = ref();

  const meta = computed(() => {
    return mergeMeta({
      ...state.value?.meta
    });
  });

  onMounted(() => {
    policyWizardMachine.value = PolicyWizardMachine.withContext({
      agentNo,
      authToken,
      policyNo,
      party,
      isEarthquake,
      optInAutoPayRenewal,
      accountType,
      addressData
    });
    policyWizardService.value = interpret(policyWizardMachine.value);
    // Start service on component creation

    policyWizardService.value
      .onTransition((s) => {
        if (typeof onTransition === "function") {
          onTransition(s);
        }

        // Update the current state component data property with the next state
        state.value = s;
        // Update the context component data property with the updated context
        context.value = s.context;
      })
      .start();

    axiosInterceptorId = axios.interceptors.response.use(
      // simpy return the response
      (response) => {
        return response;
      },
      // if the response is an error send UNAUTHORIZED to the service
      (error) => {
        if (error.isAxiosError && error.response?.status === 401) {
          policyWizardService.value?.send("UNAUTHORIZED");
        }

        return Promise.reject(error);
      }
    );
  });

  onBeforeUnmount(() => {
    // Stop the service when the component is destroyed
    policyWizardService.value?.stop();
    axios.interceptors.response.eject(axiosInterceptorId);
  });

  const send = (...params) => policyWizardService.value?.send(...params);

  const isEndorsement = computed(() => {
    return context.value?.confirmation.lastTransactionType === "EB";
  });

  provide(policyWizardKey, {
    policyWizardService,
    context,
    send,
    state,
    isEndorsement,
    meta
  });

  return {
    context,
    policyWizardService,
    send,
    state,
    isEndorsement,
    meta
  };
}

export function usePolicyWizard() {
  return inject(policyWizardKey);
}

export function mergeMeta(meta) {
  if (!meta) {
    return {};
  }

  return Object.values(meta).reduce((v, m) => ({ ...m, ...v }), {});
}
