import actions from "./actions";
import globalMutators from "../settings/modules/ui/mutations";
import mutations from "./mutations";
import getters from "./getters";

export const state = () => ({
  policyNo: null,
  /** @type {neptune.policyholder.Policy} */
  policyData: null,
  transactions: [],
  billingInfo: null,
  lienholderInfo: [],
  invoiceInfo: null,
  confirmationData: null,
  transactionSummary: null,
  addressData: null,
  paymentMethod: null
});

const mod = {
  state,
  namespaced: true,
  actions: actions,
  mutations: {
    ...globalMutators,
    ...mutations,
    completeAgentSignature(state) {
      state.confirmationData.isSigned[0].isSigned = true;
    },
    completePayment(state) {
      state.confirmationData.isPaid = true;
    }
  },
  getters
};

export default mod;
