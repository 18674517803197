<template>
  <div>
    <div class="flex flex-col space-y-2 md:space-y-0 md:flex-row md:space-x-2 items-center">
      <div>
        <button
          class="text-white font-bold text-center px-3.5 py-2.5 rounded-md min-w-[80px] text-sm"
          :class="{
            'bg-deepsea-full/10 text-deepsea-full hover:bg-deepsea-full/20': !$isEarthquake,
            'bg-orange-100 text-orange-900 hover:bg-orange-200': $isEarthquake
          }"
          data-test="updatePaymentMethodButton"
          @click.prevent="updatePaymentMethod()"
        >
          Update Payment Method
        </button>
      </div>
      <div>
        <button
          class="font-bold text-center px-3.5 py-2.5 rounded-md min-w-[80px] text-sm bg-red-100 text-red-900 hover:bg-red-200"
          data-test="removePaymentMethodButton"
          @click.prevent="removePaymentMethod()"
        >
          Remove Payment Method
        </button>
      </div>
    </div>
    <div v-if="rowData.length > 0">
      <div :loading="loading" class="max-h-96 max-w-6xl overflow-x-auto">
        <div v-if="rowData.length" class="">
          <div class="inline-block min-w-full py-2 align-middle">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="sticky top-0 bg-white">
                <tr>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    data-test="paymentMethod.brandTitle"
                  >
                    Brand
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    data-test="paymentMethod.lastFourTitle"
                  >
                    Last Four
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    data-test="paymentMethod.expirationTitle"
                  >
                    Expiration
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    data-test="paymentMethod.defaultTitle"
                  >
                    Default
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    data-test="paymentMethod.createdTitle"
                  >
                    Created
                  </th>
                </tr>
              </thead>

              <tbody class="divide-y divide-gray-200">
                <tr v-for="paymentMethod in rowData" :key="paymentMethod.id">
                  <td
                    class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 font-medium"
                    data-test="paymentMethod.paymentId"
                  >
                    <PaymentTypeDisplay :brand="paymentMethod.cardBrand" />
                    <p class="sr-only">{{ paymentMethod.cardBrand }}</p>
                  </td>
                  <td
                    class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 font-medium"
                    data-test="paymentMethod.createdAt"
                  >
                    Ending in {{ paymentMethod.lastFourDigits }}
                  </td>
                  <td
                    class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 font-medium"
                    data-test="paymentMethod.type"
                  >
                    <span v-if="paymentMethod.expiration"
                      >Expires {{ paymentMethod.expiration }}</span
                    >
                    <span v-else>--</span>
                  </td>
                  <td
                    class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 font-medium"
                    data-test="paymentMethod.status"
                  >
                    <span
                      v-if="paymentMethod.default"
                      class="el-icon-check text-green-600 text-xl font-bold"
                    />
                    <span v-else class="el-icon-close text-red-600 text-xl" />
                  </td>
                  <td
                    class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 font-medium"
                    data-test="paymentMethod.amount"
                  >
                    {{ paymentMethod.createdAt | shortDateSimple }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div v-else key="emptyState" class="flex items-center justify-center w-full">
      <div class="text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="mx-auto h-12 w-12 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            vector-effect="non-scaling-stroke"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
          />
        </svg>
        <h3 class="mt-2 text-sm font-medium text-gray-900" data-test="notificationsHeader">
          No default payment method set on this policy
        </h3>
      </div>
    </div>
    <el-dialog
      :visible.sync="showUpdatePayment"
      :append-to-body="true"
      title="Update Payment Method"
      custom-class="!mx-4 md:!mx-auto h-min !w-full md:w-min md:max-w-screen-sm md:max-h-[65vh] overflow-y-auto"
    >
      <UpdatePaymentMethod
        v-if="showUpdatePayment"
        :policy="allData"
        :is-earthquake="isEarthquake"
        :payment-interval="paymentInterval"
        :opt-in-autopay-renewal="optInAutopayRenewal"
        :is-paid="isPaid"
        @close="closeUpdateModal"
        @done="onUpdatePaymentMethod"
      />
    </el-dialog>

    <el-dialog
      :visible.sync="showRemovePayment"
      :append-to-body="true"
      title="Remove Payment Method"
      custom-class="!w-fit max-w-screen-md h-min"
    >
      <ConfirmRemovePaymentMethod
        :policy="allData"
        :payment-method="paymentMethod"
        @close="closeRemovePaymentMethod"
        @done="onRemovePaymentMethod"
      />
    </el-dialog>
  </div>
</template>

<script>
import { DateFiltersMixin, NumberFiltersMixin } from "../../policy-wizard/utils/mixins/formatting";
import { mapActions } from "vuex";
import UpdatePaymentMethod from "../../routes/policy-details/UpdatePaymentMethod.vue";
import ConfirmRemovePaymentMethod from "../../routes/policy-details/ConfirmRemovePaymentMethod.vue";
import PaymentTypeDisplay from "../policy-detail/PaymentTypeDisplay.vue";

export default {
  components: {
    ConfirmRemovePaymentMethod,
    UpdatePaymentMethod,
    PaymentTypeDisplay
  },
  mixins: [DateFiltersMixin, NumberFiltersMixin],
  inheritAttrs: false,
  model: {
    prop: "rowData"
  },
  props: {
    policyNo: {
      type: String
    },
    paymentMethod: {
      type: Object,
      default: () => ({})
    },
    isPaid: {
      type: Boolean,
      default: true
    }
  },
  emits: ["refreshPaymentMethod"],
  data() {
    return {
      loading: false,
      payment: {
        checkNo: "",
        payeeName: "",
        memo: "",
        amount: null,
        createdAt: null
      },
      dialogShowApplyPayment: false,
      showUpdatePayment: false,
      showRemovePayment: false
    };
  },
  computed: {
    rowData() {
      // for now we only support one default payment method per policy
      // may change in the future
      return [this.paymentMethod];
    },
    isEarthquake() {
      return (
        this.$store.getters["policy/productNo"] === 4 ||
        this.$store.getters["policy/productNo"] === 5
      );
    },
    allData() {
      return this.$store.getters["policy/allData"];
    },
    showPaymentChangeButton() {
      return Boolean(this.paymentMethod?.default);
    },
    paymentInterval() {
      return this.$store?.state?.policy?.confirmationData?.paymentInterval;
    },
    optInAutopayRenewal() {
      return this.paymentMethod?.optInAutoPayRenewal;
    }
  },
  methods: {
    validateKeypress($event) {
      if (
        ($event.charCode !== 8 && $event.charCode === 0) ||
        ($event.charCode >= 48 && $event.charCode <= 57)
      ) {
        return true;
      } else {
        $event.preventDefault();
      }
    },
    onReceiptClick(payment) {
      this.$router.push({
        path: `${this.$route.fullPath}/receipt/${payment.policyPaymentId}`,
        params: {
          createdAt: payment.createdAt
        }
      });
    },
    transfer(payment) {
      this.$root.$emit("openTransferPaymentModal", payment);
    },
    showApplyPaymentDialog() {
      this.payment = {
        checkNo: "",
        payeeName: "",
        memo: "",
        amount: null,
        createdAt: null
      };
      this.dialogShowApplyPayment = true;
    },
    ...mapActions("payment", ["postCheckPayment"]),
    async updatePaymentMethod() {
      this.showUpdatePayment = true;
    },
    removePaymentMethod() {
      this.showRemovePayment = true;
    },
    closeRemovePaymentMethod() {
      this.showRemovePayment = false;
    },
    onRemovePaymentMethod() {
      this.showRemovePayment = false;
      this.$emit("refreshPaymentMethod");
    },
    closeUpdateModal() {
      this.showUpdatePayment = false;
    },
    onUpdatePaymentMethod() {
      this.showUpdatePayment = false;
      this.$emit("refreshPaymentMethod");
    },
    showPaymentError() {
      this.$notify.error({
        title: "Error",
        message: "There was an error applying this check."
      });
    },
    showCheckSuccess() {
      this.$notify({
        title: "Success",
        message: "Check was successfully applied!",
        type: "success"
      });
    },

    cancelApplyPaymentDialog() {
      this.dialogShowApplyPayment = false;
    },
    canTransferPayment(payment) {
      const { invoices } = this.allData;
      const { isAppliedToPolicy, amount, paymentStatus } = payment;

      const paymentArr = this.rowData.filter((x) => x.paymentId === payment.paymentId);
      let total = 0;
      paymentArr.map((x) => (total = x.amount + total));
      return (
        this.$internal &&
        !this.$readonly &&
        !(
          total === 0 ||
          paymentStatus === "failed" ||
          amount <= 0 ||
          paymentStatus === "cancelled" ||
          isAppliedToPolicy === false ||
          (invoices.due === 0 && invoices.paid === 0) ||
          payment.paymentTypeId === 12
        )
      );
    },
    async applyPayment() {
      try {
        this.loading = true;
        await this.postCheckPayment({ policyNo: this.policyNo, checkBody: this.payment });
        await this.$store.dispatch("policy/getPolicyDetails", this.policyNo);
        this.dialogShowApplyPayment = false;
        this.showCheckSuccess();
      } catch (e) {
        this.showPaymentError();
      } finally {
        this.loading = false;
      }
    },
    determineTitle(payment) {
      const { isAppliedToPolicy, amount, paymentStatus } = payment;
      if (paymentStatus === "failed") {
        return "Payment failed";
      } else if (amount < 0) {
        return "Payment has already been transferred";
      } else if (paymentStatus === "cancelled") {
        return "Payment has been cancelled";
      } else if (!isAppliedToPolicy) {
        return "Payment has not been applied";
      } else if (!this.isInternal) {
        return "Internal use only";
      } else {
        return "Transfer payment";
      }
    },
    canShowReceipt(payment) {
      return payment.paymentTypeId !== 12;
    }
  }
};
</script>
<style lang="scss" scoped>
.amount-date-row {
  @media screen and (max-width: 1440px) {
    display: flex;
    flex-direction: column;
  }
}

::v-deep .dialog-class {
  @media screen and (max-width: 1440px) {
    height: 850px;
  }

  min-width: 490px;
}

::v-deep .update-dialog {
  height: 100%;
  width: 100%;

  @media screen and (min-width: 768px) {
    max-width: 500px;
    max-height: 65vh;
    margin: unset;
    margin-left: auto;
    margin-right: auto;
  }

  // need to set !important up to 767
  // in order to address element-ui margin style
  // directly on the element.
  @media screen and (max-width: 767px) {
    margin: 0 !important;
  }
}
</style>
