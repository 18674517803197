<template>
  <div
    :class="{
      loading,
      'is-agent': party === 1,
      jswizard: isEarthquake,
      'flex bg-lightair-full text-jetblack-full': !isEarthquake
    }"
    class="h-full"
  >
    <div
      class="flex-grow relative"
      :class="{
        'overflow-auto max-h-[100vh]': !signatureModal.open,
        'overflow-hidden': signatureModal.open
      }"
    >
      <div v-if="loading && !errorCheck" class="auto-bind">
        <div class="lottie animated fadeIn faster">
          <lottie-viewer loop path="static/lottie/preloader3.json" />
          <div :class="{ loadingText: isEarthquake }">
            <div class="status">Loading</div>
            <div class="status">Please wait...</div>
          </div>
        </div>
      </div>

      <!-- close modal -->
      <div
        v-if="isModal"
        class="absolute top-0 right-2 p-4 cursor-pointer z-10"
        @click="$root.$emit('closeModal')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5 transition duration-100"
          :class="{
            'text-black text-black/75': isEarthquake,
            'text-deepsea-full hover:text-deepsea-full/75': !isEarthquake
          }"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </div>

      <div v-if="errorCheck" class="error-container">
        <h3 data-test="paymentError.whoops">Whoops! Something went wrong</h3>
        <p class="px-4 text-base mt-5" data-test="paymentError.error">{{ errorCheck }}</p>
        <h3 class="mt-5 mb-5" data-test="paymentError.supportContact">
          Feel free to retry or contact customer support at
          <a href="mailto:support@neptuneflood.com">support@neptuneflood.com</a>
        </h3>
        <button
          style="margin-top:35px"
          class="!px-4 !py-2 rounded bg-deepsea-medium text-white hover:bg-deepsea-900"
          data-test="paymentError.retry"
          @click="sendRetry"
        >
          Retry
        </button>
      </div>
      <SignDocumentsModal
        v-else-if="showSignatureScreen"
        :party="context.party"
        :documents="policyDocuments"
        :loading="docLoading"
        :policy-data="context"
        @submitStart="loading"
        @signaturesSubmission="finalSubmit"
        @skipToPayment="skipToPay"
      />
      <PayWiz
        v-else-if="showPayScreen"
        class="px-4 lg:px-6"
        :state="state"
        :show-view-policy="isModal"
        @togglePaymentType="onTogglePaymentType"
        @startACH="makeACHPayment"
        @ccComplete="ccComplete"
        @checkComplete="checkComplete"
        @clickToClose="clickToClose"
      />
      <FinishScreen
        v-else-if="showFinishScreen"
        :state="state"
        :is-modal="isModal"
        @clickToClose="clickToClose"
      />
      <Unauthorized v-else-if="isUnauthorized" />
    </div>

    <!-- Sidebar -->
    <div class="sidebar md:!block flex-shrink-0">
      <SideBar v-if="showSummary" :is-modal="isModal" :model="state" @clickToClose="clickToClose" />
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { computed, getCurrentInstance, onMounted, ref } from "vue";
import SideBar from "./components/PolicyHolderSummary.vue";
import FinishScreen from "./steps/FinishScreen.vue";
import LottieViewer from "./components/LottieViewer.vue";
import SignDocumentsModal from "@/components/SignDocumentsModal.vue";
import PayWiz from "./steps/pay";
import { Constants } from "../../config";
import { providePolicyWizard } from "./use-policy-wizard.js";
import Unauthorized from "./components/Unauthorized.vue";

const props = defineProps({
  isModal: {
    type: Boolean,
    default: true
  },
  policyNo: {
    type: String,
    default: ""
  },
  accountType: {
    type: String
  }
});

const emit = defineEmits(["clickToClose"]);

const {
  proxy: { $ada, $store, $root, $route, $router }
} = getCurrentInstance();

const hasSetMeta = ref(false);
const signatureModal = ref({
  open: false
});

// if the user has the role 'noPayOptIn', it means
// we want the default for optInAutoPayRenewal to be false
// otherwise, we want the default to be true
const optInAutoPayRenewal = !$store.getters["auth/noPayOptIn"];

const { context, policyWizardService, send, state } = providePolicyWizard({
  agentNo: $store.getters["auth/agentNo"],
  authToken: $store.state.auth.token,
  policyNo:
    $store.state.policy.confirmationData?.policyNo ?? $store.state.auth.validateReturn.policyNo,
  party: $store.getters["userdata/party"],
  accountType: props.accountType,
  optInAutoPayRenewal,
  addressData: $store.state.policy.addressData,
  onTransition: (s) => {
    if (!hasSetMeta.value && s.context.confirmation) {
      const { email, firstName, lastName, phone } = s.context.confirmation;
      $ada.setSensitiveMetaFields({
        environment: Constants.ENV,
        policyholder_name: firstName && lastName ? `${firstName} ${lastName}` : firstName,
        policyholder_email: email,
        policyholder_phone: phone
      });
      $ada.setMetaFields({
        policy_number:
          $store.state.policy.confirmationData?.policyNo ??
          $store.state.auth.validateReturn.policyNo
      });
      hasSetMeta.value = true;
    }
  }
});

const isEarthquake = computed(() => {
  if (!context.value) return false;

  const { transactions } = context.value;
  if (!transactions || (Array.isArray(transactions) && !transactions.length)) return false;

  const [{ productId }] = transactions;

  return productId === 4 || productId === 5;
});

const isCommercial = computed(() => {
  if (!context.value) return false;

  const { transactions } = context.value;
  if (!transactions || (Array.isArray(transactions) && !transactions.length)) return false;

  const [{ productId }] = transactions;

  return [2, 3, 7, 8].includes(productId);
});

const party = computed(() => {
  return context.value?.party;
});

const errorCheck = computed(() => {
  return context.value?.error?.message;
});

const loading = computed(() => {
  const loading_states = [
    "fetch_policy.fetch_policy_confirmation.begin",
    "fetch_policy.fetch_policy_transactions.begin",
    "fetch_policy.fetch_policy_transactions.address_data",
    "signatures.begin",
    "signatures.submit_signatures",
    "payments.begin",
    "payment.fetch_payment_methods"
  ];

  return loading_states.some((s) => state.value?.matches(s));
});

const isUnauthorized = computed(() => {
  return state.value?.matches("unauthorized");
});

const docLoading = computed(() => {
  const loading_states = ["signatures.submit_signatures"];

  return loading_states.some((s) => state.value?.matches(s));
});

const fetchingPaymentMethod = computed(() => {
  return state.value?.matches("payment.fetch_payment_methods");
});

const showSignatureScreen = computed(() => {
  return state.value?.matches("signatures.loaded");
});

const showPayScreen = computed(() => {
  return state.value?.matches("payment.loaded");
});

const showFinishScreen = computed(() => {
  return state.value?.matches("finish");
});

const policyDocuments = computed(() => {
  return context.value?.documents?.policyDocStatus?.documentsWithSignatures;
});

const showSummary = computed(() => {
  return (
    showSignatureScreen.value ||
    showPayScreen.value ||
    showFinishScreen.value ||
    fetchingPaymentMethod.value ||
    state.value?.matches("signatures.submit_signatures")
  );
});

onMounted(() => {
  $root.$on("toggleSignatureModalScroll", () => {
    signatureModal.value = {
      open: signatureModal.value.open
    };
  });
});

const skipToPay = () => {
  policyWizardService.value.send({ type: "SUBMIT_SIGNATURES" });
};

const clickToClose = () => {
  if ($route.name !== "policyWelcome") {
    emit("clickToClose");
  } else {
    $router.push({
      name: "policyHolderDetail",
      params: {
        policyNo: props.policyNo
      }
    });
  }
};

const finalSubmit = () => {
  policyWizardService.value.send({ type: "SUBMIT_SIGNATURES" });
};

const makeACHPayment = (data) => {
  policyWizardService.value.send({ type: "ON_MAKE_ACH_PAYMENT", data });
  $store.commit("policy/completePayment");
};

const ccComplete = (data) => {
  policyWizardService.value.send({ type: "ON_MAKE_CC_PAYMENT", data });
};

const checkComplete = () => {
  policyWizardService.value.send({ type: "ON_MAKE_CHECK_PAYMENT" });
};

const sendRetry = () => {
  send({ type: "RETRY" });
};

const onTogglePaymentType = (type) => {
  switch (type) {
    case "PaymentAch":
      policyWizardService.value.send({ type: "MAKE_ACH_PAYMENT" });
      break;
    case "PaymentMortgagee":
      policyWizardService.value.send({ type: "MAKE_MORTGAGEE_PAYMENT" });
      break;
    case "PaymentCc":
      policyWizardService.value.send({ type: "MAKE_CC_PAYMENT" });
      break;
    case "applyCheck":
      policyWizardService.value.send({ type: "APPLY_CHECK" });
      break;
    case "PaymentCheck":
      policyWizardService.value.send({ type: "LOCKBOX_INFO" });
      break;
    case "CCMessage":
      policyWizardService.value.send({ type: "CC_MESSAGE" });
  }
};
</script>

<style lang="scss" scoped>
.policy-wizard {
  transition: background 500ms ease;

  &.is-agent {
    background-color: #0c5655;
    .theme-earthquake & {
      background-color: #ff614c;
    }
  }

  &-content {
    margin: 0 auto;

    .ie &,
    .k-ie11 & {
      display: block;
      width: 100%;
    }

    transition: transform 300ms ease;
    .loading & {
      transform: scale(0.9);
      opacity: 0.5;
    }

    ::v-deep {
      .header-title {
        font-size: 1.3rem;
        text-align: center;
        text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.62);

        p {
          margin: 1rem 0;
        }

        // This centers the logo
        img {
          margin: 0 auto 5rem;
        }
      }

      h1 {
        font-size: 3rem;
      }

      h2 {
        font-size: 2.5rem;
      }

      h3 {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
      }

      h1,
      h2,
      h3,
      h4,
      h5 {
        line-height: normal;
      }
    }
  }
  &-component {
    margin-bottom: 300px;

    transition: opacity 500ms ease, filter 500ms ease;

    .loading & {
      opacity: 0.5;
    }

    ::v-deep a {
      color: #94d0ff;
      font-weight: 600;
      &:hover,
      &:active {
        color: #03f4f4;
      }
    }
  }

  ::v-deep .jumbo-button {
    font-size: 2rem;
  }

  scrollbar-width: thin;

  .is-desktop & {
    ::-webkit-scrollbar {
      background-color: transparent;
      width: 4px;
      height: 4px;

      &-track {
        padding: 2px;
        background-color: rgba(#000, 0.15);
      }

      &-thumb {
        background-color: #444444;
        border-radius: 6px;

        &::before {
          content: "";
          width: 2px;
          height: 100%;
          display: block;
          background-color: #636363;
        }
      }
    }
  }
}

.sidebar {
  display: none;
  background-color: darken(#fff, 1);
  color: lighten(#000, 5);
  max-width: 350px;
  overflow-x: hidden;
  overflow-y: auto;
  border-right: 3px solid rgba(255, 255, 255, 0);

  position: absolute;
  right: 0;
  height: 100vh;
  box-shadow: 0 0 6px rgba(#000, 0.5);
  transform: translateX(100%);
  width: 0;
  transition: transform 200ms ease, width 200ms ease;

  @media screen and (min-width: 1024px) {
    position: relative;
    right: auto;
    width: auto;
    transform: none;
  }

  > * {
    padding: 1rem;
  }
}

.floating-status {
  margin: auto;
}

::v-deep img.logo {
  height: 50px;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.6));
  position: relative;
  top: 2rem;
}

::v-deep .header-title {
  padding: 0 2rem;
}

.debugger {
  position: absolute;
  opacity: 0.01;
  transition: opacity 100ms ease;
  top: 0;
  left: 0;
  margin: 5px;
  padding: 1rem;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;
  display: grid;
  align-items: center;
  grid-template-rows: auto;
  grid-template-columns: repeat(4, auto);
  grid-gap: 20px;
  font-size: 0.7rem;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.7);

  ::v-deep * {
    font-size: 1em !important;
  }

  &:hover {
    opacity: 1;
  }

  .stepper {
    display: flex;
    flex-flow: column;

    color: #000;
  }
}

.spinner {
  transition: all 2s ease;
  transform: perspective(1000px) translate3d(0, 0, 25px);
  opacity: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  .loading & {
    opacity: 1;
    transform: perspective(1000px) translate3d(0, 0, 0);
    background-color: hsla(0, 0%, 0%, 0.41);
  }

  &__inner {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
}

::v-deep .popup {
  @media screen and (max-width: 767px) {
    .el-card {
      display: flex;
      flex-flow: column;

      &__header {
        box-shadow: 0px 2px 9px hsla(0, 0%, 0%, 0.55);
        position: relative;
        z-index: 1000;
      }

      &__body {
        flex: 1;
        overflow: auto;
      }
    }
  }
}
.auto-bind {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lottie {
  max-width: 300px;
  text-align: center;
  font-weight: bold;
  font-size: 1.6rem;
}
.error-container {
  margin-top: 17rem;
  text-align: center;
}
// js styles
.jswizard {
  display: flex;
  background: linear-gradient(-30deg, #f9e7c6, #ffdaed 60%);
  color: #fff;
  transition: background 500ms ease;
  &-wrap {
    flex: 1;
    display: flex;
    position: relative;
    &__inner {
      position: relative;
      flex: 1;
      display: flex;
      overflow-x: hidden;
      overflow-y: scroll;
      border-right: 3px solid rgba(255, 255, 255, 0);
      background: linear-gradient(-30deg, #f9e7c6, #ffdaed 60%);

      &::-webkit-scrollbar-thumb {
        background-color: rgba(#fff, 0.5) !important;
      }
      .loading & {
        pointer-events: none;
      }
    }
  }
  &-content {
    margin: 1rem auto auto;
    .ie &,
    .k-ie11 & {
      display: block;
      width: 100%;
    }
    transition: transform 300ms ease;
    .loading & {
      transform: scale(0.9);
      opacity: 0.5;
    }
    ::v-deep {
      .header-title {
        font-size: 1.3rem;
        text-align: center;
        text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.62);
        p {
          margin: 1rem 0;
        }
        img {
          margin: 0 auto 5rem;
        }
      }
      h1 {
        font-size: 3rem;
      }
      h2 {
        font-size: 2.5rem;
      }
      h3 {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
      }
      h1,
      h2,
      h3,
      h4,
      h5 {
        line-height: normal;
      }
    }
  }
  &-component {
    margin-bottom: 300px;
    transition: opacity 500ms ease, filter 500ms ease;
    .loading & {
      opacity: 0.5;
    }
    ::v-deep a {
      color: #94d0ff;
      font-weight: 600;
      &:hover,
      &:active {
        color: #03f4f4;
      }
    }
  }
  ::v-deep .jumbo-button {
    font-size: 2rem;
  }
  scrollbar-width: thin;
  .is-desktop & {
    ::-webkit-scrollbar {
      background-color: transparent;
      width: 4px;
      height: 4px;
      &-track {
        padding: 2px;
        background-color: rgba(#000, 0.15);
      }
      &-thumb {
        background-color: #444444;
        border-radius: 6px;
        &::before {
          content: "";
          width: 2px;
          height: 100%;
          display: block;
          background-color: #636363;
        }
      }
    }
  }
}
.loadingText {
  background-color: #00000070;
}
</style>
