import {
  fetch_policy_confirmation,
  fetch_policy_transactions,
  fetch_policy_documents,
  fetch_payment_methods,
  save_signatures,
  make_ach_payment,
  load_stripe,
  get_client_secret,
  get_transaction_fee,
  confirm_setup_intent,
  process_payment,
  confirm_ach,
  get_client_secret_triton,
  get_transaction_fee_triton,
  handleCardAction,
  fetch_address_data
} from "./services";

import {
  isSignedByParty,
  isPaymentRequired,
  isBillInitiallyMortgagee,
  isFlood,
  isEarthquakeMonthly,
  isFeeAcknowledged,
  isEndorsement,
  isPolicyholder,
  requiresCardAction,
  hasAddressData
} from "./guards";

import {
  clearError,
  setACHResponse,
  setError,
  setConfirmation,
  setDocuments,
  setPaymentMethods,
  setSignatures,
  setTransactions,
  setStripe,
  setClientSecret,
  setPaymentMethod,
  setPaymentMethodFromSetupIntent,
  setTransactionFees,
  clearTransactionFees,
  setAckFee,
  setOptInAutoPayRenewal,
  setTransactionFeesFromPayload,
  setProcessPaymentError,
  logout,
  redirectInsured,
  redirectAgent,
  setPaymentError,
  clearPaymentError,
  setAddressData
} from "./actions";

export default {
  actions: {
    clearError,
    setACHResponse,
    setError,
    setConfirmation,
    setDocuments,
    setPaymentMethods,
    setSignatures,
    setTransactions,
    setStripe,
    setClientSecret,
    setPaymentMethod,
    setPaymentMethodFromSetupIntent,
    setTransactionFees,
    clearTransactionFees,
    setAckFee,
    setOptInAutoPayRenewal,
    setTransactionFeesFromPayload,
    setProcessPaymentError,
    logout,
    redirectInsured,
    redirectAgent,
    setPaymentError,
    clearPaymentError,
    setAddressData
  },
  guards: {
    isSignedByParty,
    isPaymentRequired,
    isBillInitiallyMortgagee,
    isFlood,
    isEarthquakeMonthly,
    isFeeAcknowledged,
    isEndorsement,
    isPolicyholder,
    requiresCardAction,
    hasAddressData
  },
  services: {
    fetch_policy_transactions,
    fetch_policy_confirmation,
    fetch_policy_documents,
    fetch_payment_methods,
    save_signatures,
    make_ach_payment,
    load_stripe,
    get_client_secret,
    get_transaction_fee,
    confirm_setup_intent,
    process_payment,
    confirm_ach,
    get_client_secret_triton,
    get_transaction_fee_triton,
    handleCardAction,
    fetch_address_data
  }
};
